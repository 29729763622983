import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  description,
  lang,
  meta,
  title,
  company,
  siteUrl,
  socialImage,
  salesEmail,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            company
            siteUrl
            socialImage
            salesEmail
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const metaImageUrl = socialImage
    ? socialImage.url
    : `${site.siteMetadata.siteUrl}${site.siteMetadata.socialImage}`

  const metaImageAlt = socialImage?.alt || "Evexia"

  const metaTitle = title || site.siteMetadata.title;

  const metaUrl = site.siteMetadata.siteUrl

  const generalTags = [
    { name: "author", content: site.siteMetadata.author },
    { name: "copyright", content: site.siteMetadata.author },
    { name: "description", content: metaDescription },
  ]

  const itemPropTags = [
    { itemprop: "description", content: metaDescription },
    { itemprop: "image", content: metaImageUrl },
  ]

  const openGraphTags = [
    { property: "og:site_name", content: site.siteMetadata.author },
    { property: "og:url", content: metaUrl },
    { property: "og:locale", content: "EN" },
    { property: "og:title", content: metaTitle },
    { property: "og:description", content: metaDescription },
    { property: "og:image", content: metaImageUrl },
    { property: "og:email", content: site.siteMetadata.salesEmail },
  ]

  const twitterTags = [
    { name: "twitter:card", content: "summary" },
    { name: "twitter:title", content: metaTitle },
    { name: "twitter:description", content: metaDescription },
    { name: "twitter:image", content: metaImageUrl },
    { name: "twitter:site", content: site.siteMetadata.twitter },
    { name: "twitter:domain", content: metaUrl },
  ]

  if (metaImageAlt) {
    openGraphTags.push({ property: "og:image:alt", content: metaImageAlt })
    twitterTags.push({ name: "twitter:image:alt", content: metaImageAlt })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        ...generalTags,
        ...openGraphTags,
        ...twitterTags,
        ...itemPropTags
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  company: ``,
  siteUrl: ``,
  socialImage: ``,
  salesEmail: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  socialImage: PropTypes.string,
  salesEmail: PropTypes.string.isRequired,
}

export default SEO
