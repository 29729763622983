// Theme

const theme = {
  font: {
    accent: {
      light: "font-family: 'Nunito', sans-serif; font-weight: 300",
      regular: "font-family: 'Nunito', sans-serif; font-weight: 400",
      medium: "font-family: 'Nunito', sans-serif; font-weight: 600",
      bold: "font-family: 'Nunito', sans-serif; font-weight: 700;",
      extraBold: "font-family: 'Nunito', sans-serif; font-weight: 800",
      black: "font-family: 'Nunito', sans-serif; font-weight: 900",
    },
    body: {
      light: "font-family: 'Roboto', sans-serif; font-weight: 300",
      regular: "font-family: 'Roboto', sans-serif; font-weight: 400",
      medium: "font-family: 'Roboto', sans-serif; font-weight: 500",
      bold: "font-family: 'Roboto', sans-serif; font-weight: 700",
      // extraBold: "",
      black: "font-family: 'Roboto', sans-serif; font-weight: 900",
    },
    primary: `'HK Grotesk Normal'`,
    secondary: `'HK Grotesk Medium'`,
    light: `'HK Grotesk Light'`,
    normal: `'HK Grotesk Normal'`,
    medium: `'HK Grotesk Medium'`,
    semibold: `'HK Grotesk Semibold'`,
    bold: `'HK Grotesk Bold'`,
    extrabold: `'HK Grotesk Extra Bold'`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    smallplus: "font-size: 20px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 28px; line-height: 30px;",
    larger: "font-size: 40px; line-height: 50px;",
    xlarge: "font-size: 48px; line-height: 48px; letter-spacing: 0.5px;",
  },

  color: {
    "grey-900": "#2A2222",
    "grey-800": "#3F3535",
    "grey-700": "#564A4A",
    "grey-600": "#958787",
    "grey-500": "#BCAEAE",
    "grey-400": "#D9CFCF",
    "grey-300": "#E6DFDF",
    "grey-200": "#EFE9E9",
    "grey-100": "#F4F0F0",
    "grey-50": "#F9F7F7",
    "primary-900": "#AD0403",
    "primary-800": "#C51D1C",
    "primary-700": "#DD2624",
    "primary-600": "#EB403E",
    "primary-500": "#F95A59",
    "primary-400": "#FC7372",
    "primary-300": "#FEB2B1",
    "primary-200": "#FDD7D7",
    "primary-100": "#FFF5F4",
    "accent-900": "#172838",
    "accent-800": "#25496E",
    "accent-700": "#34689D",
    "accent-600": "#3872AD",
    "accent-500": "#4583C3",
    "accent-400": "#558FCA",
    "accent-300": "#6FA1D3",
    "accent-200": "#B1D3F2",
    "accent-100": "#F0F8FE",
    white: "white",
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
  spacing: {
    xxSmall: "1px",
    xSmall: "2px",
    small: "4px",
    regular: "8px",
    large: "16px",
    xLarge: "32px",
    xxLarge: "64px",
    xxxLarge: "128px",
  },
}

export default theme
